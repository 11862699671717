var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "tasks",
          expression: "widget.compType === 'tasks'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "宽度(%)" } },
          [
            _c("el-input-number", {
              attrs: { min: 0, max: 100 },
              model: {
                value: _vm.widget.properties.width,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "width", $$v)
                },
                expression: "widget.properties.width",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "高度(px)" } },
          [
            _c("el-input-number", {
              staticClass: "input",
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.height,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "height", $$v)
                },
                expression: "widget.properties.height",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "显示标签" } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showLabel", $$v)
                },
                expression: "widget.properties.showLabel",
              },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }